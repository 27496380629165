.ctaCard.-type-1 {
  position: relative;
  z-index: 0;

  .ctaCard {
    &__image {
      border-radius: inherit;
    }

    &__content {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;

      display: flex; /* Add flex display */
      flex-direction: column; /* Stack children vertically */
      justify-content: center; /* Center vertically */
      align-items: center; /* Center horizontally */
      text-align: center; /* Center align text */
      
      &::before {
        content: "";
        border-radius: inherit;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(30, 40, 67, 0.7) 0%, rgba(221, 216, 208, 0) 100%);
      }
    }
  }

  &.-no-overlay {
    .ctaCard__content::before {
      display: none;
    }
  }

  &:hover {
  }
}
