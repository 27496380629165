.mapFilter {
  overflow: hidden;
  position: fixed;
  top: 30px;
  bottom: 70px;
  left: 30px;
  right: 30px;
  z-index: 1000;
  border-radius: 4px;
  transition: all 0.6s $easeOutQuart;
  pointer-events: none;
  opacity: 0;
  
  @media (max-width: (1400px)) {
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  
  @include media-below(xl) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }

  &.-is-active {
    pointer-events: auto;
    opacity: 1;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: color-opac(dark-1, 0.4);
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;

    @include media-below(sm) {
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__grid {
    display: grid;
    gap: 0;
    grid-template-columns: 515px 1fr;
    width: 100%;
    height: 100%;
    background-color: white;
    padding-left: 340px;
    transition: all 0.4s $easeOutQuart;
    
    @include media-below(lg) {
      grid-template-columns: 420px 1fr;
    }
    
    @include media-below(md) {
      grid-template-columns: 250px 1fr;
      padding-left: 330px;
      
      .ratio {
        width: 100%;
        height: auto;
      }
    }
    
    @include media-below(sm) {
      grid-template-columns: 1fr;
    }

    &.-filters-hidden {
      padding-left: 80px;
      
      .mapFilter-filter {
        transform: translateX(-255px);
      }
      
      @include media-below(md) {
        padding-left: 70px;
      }
    }
  }
}

.mapFilter-filter {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 10px 35px 0px #0510361A;
  transition: all 0.5s $easeOutQuart;

  &__item {
    border-top: 1px solid var(--color-border);
    padding-top: 20px;
    margin-top: 20px;
  }
}

.mapFilter-results {
  overflow-y: scroll;
  transition: all 0.6s $easeOutQuart;

  @include media-below(sm) {
    display: none;
  }

  &__item {
    border-top: 1px solid var(--color-border);
    padding-top: 20px;
    margin-top: 20px;
  }
}

.mapFilter-map {
  transition: all 0.6s $easeOutQuart;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.scroll-bar-1 {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e7e7e7;
    border-radius: 4px;
  }
}
