.cardImage {
  position: relative;
  z-index: 0;
  border-radius: inherit;
  height: 100%;
  @include media-below(sm) {
    height: 200px !important;
    #home_hotels .swiper-slide {
      height: 180px !important;
    }
  }


  &__xs {
    height: 350px;
  }
  &__mobile{
    height: 200px !important;
  }

  &:hover {
    .cardImage-slider__nav {
      opacity: 1 !important;
    }

    .pagination__item {
      opacity: 1 !important;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: inherit;
    background: #ddd8d0;
    
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  .cardImage-slider {
    .swiper-wrapper {
      position: relative;
      z-index: 0;
    }

    &__pagination {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      color: rgba(white, 0.7);

      &.-sm {
        bottom: 15px;
      }

      > * + * {
        margin-left: 7px;
      }

      .pagination__item {
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background-color: currentColor;
        transition: all 0.3s $easeOutQuart;
        transform: scale(0.75);
        opacity: 0;

        &.is-active {
          transform: scale(1);
          background-color: var(--color-white);
          opacity: 1;
        }
      }
    }

    &__nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 0.2s $easeOutQuart;
      opacity: 0;
      z-index: 2;

      &.-prev {
        left: 20px;
      }
      &.-next {
        right: 20px;
      }
    }
  }

  &__wishlist {
    position: absolute;
    top: 20px;
    right: 20px;
    left: auto;
    width: auto;
    height: auto;

    &.-sm {
      top: 10px;
      right: 10px;
    }
  }

  &__leftBadge {
    position: absolute;
    top: 20px;
    left: 0;
  }
}
