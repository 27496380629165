.sectionTitle {
  &__title {
  }

  &__text {}

  &.-md {
    .sectionTitle {
      &__title {
        font-size: var(--text-30);

        @include media-below(md) {
          font-size: var(--text-26);
        }
      }
    }
  }

  &.-lg {
    .sectionTitle {
      &__title {
        font-size: var(--text-40);
      }
    }
  }
}
