.citiesCard.-type-1 {
  position: relative;
  z-index: 0;

  .citiesCard {
    &__image {
      border-radius: inherit;

      img {
        width: 100%;
        // height: 100% !important;
        object-fit: cover;
        background: #ddd8d0;
      }
    }

    &__content {
      overflow: hidden;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      // background: #ddd8d0;
    }

    &__bg {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: color-opac(dark-1, 0.4);
      border-radius: inherit;
      transition: all 0.5s $easeOutQuart;
      opacity: 0;
    }

    &__top {
      transition: all 0.5s $easeOutQuart;
      opacity: 0;
    }

    &__bottom {
      transition: all 0.5s $easeOutQuart;
      transform: translateY(80px);
    }
    
    &__xs {
      height: 350px;
    }
    &__xs iframe {
      height: 350px;
    }
  }

  &:hover {
    cursor: pointer;

    .citiesCard {
      &__top {
        opacity: 1;
      }

      &__bottom {
        transform: translateY(0);
      }

      &__bg {
        opacity: 1;
      }
    }
  }
}

.citiesCard.-type-2 {
  position: relative;
  z-index: 0;

  .citiesCard {
    &__image {
      overflow: hidden;

      img {
        transition: all 0.6s $easeOutQuart;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .citiesCard {
      &__image {
        img {
          transform: scale(1.15);
        }
      }
    }
  }
}

.citiesCard.-type-3 {
  position: relative;
  z-index: 0;

  .citiesCard {
    &__image {
      overflow: hidden;
      border-radius: inherit;
      width: 100%;
      height: 100%;

      img {
        border-radius: inherit;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.6s $easeOutQuart;
      }
    }

    &__content {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;

      &::before {
        content: "";
        position: absolute;
        border-radius: inherit;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(5, 16, 54, 0.6) 0%, rgba(5, 16, 54, 0) 63.5%);
      }
    }
  }

  &:hover {
    .citiesCard__image {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.citiesCard.-type-4 {
  .citiesCard {
    &__image {
      overflow: hidden;

      img {
        transition: all 0.6s $easeOutQuart;
      }
    }
  }

  &:hover {
    .citiesCard__image {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.citiesCard.-type-5 {
  transition: all 0.3s $easeOutQuart;

  .citiesCard {
  }

  &:hover {
    background-color: var(--color-blue-1) !important;

    * {
      color: white !important;
    }
  }
}
