// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";
// @import "../../node_modules/react-modal-video/scss/modal-video.scss";
// @import "bootstrap/dist/css/bootstrap.min.css";

@import "./abstracts/tools";
@import "./abstracts/variables";

@import "./animations/easings.scss";
@import "./animations/animations.scss";
@import "./animations/core.scss";

@import "./base/base";
@import "./base/button";
@import "./base/form";
@import "./base/typography";

@import "./components/404.scss";
@import "./components/sectionTitle.scss";
@import "./components/masthead.scss";
@import "./components/citiesCard.scss";
@import "./components/ctaCard.scss";
@import "./components/tabs.scss";
@import "./components/accordion.scss";
@import "./components/header.scss";
@import "./components/cardImage.scss";
@import "./components/footer.scss";
@import "./components/mainSearch.scss";
@import "./components/featureIcon.scss";
@import "./components/testimonials.scss";
@import "./components/pagination.scss";
@import "./components/dashboard.scss";
@import "./components/blogCard.scss";
@import "./components/dragSlider.scss";
@import "./components/niceSelect.scss";
@import "./components/mapFilter.scss";
@import "./components/hotelsCard.scss";

@import "./components/preloader.scss";

@import "./utilities/spacing";
@import "./utilities/utilities";

@import "./vendors/icons.scss";

// custom scss for all site & slick
@import "./custom/custom-slick";
@import "./custom/price-range-slider";
@import "./custom/custom-next";

.css-hlgwow {
  padding: 0px 0px !important;
}
#hotel-list-v3-form .css-1rn7yzg-control {
    background-color: #f5f5f5 !important;
  }

.error-message {
  position: absolute;
  top: 66px;
  font-size: 12px;
  color: red;
}
.error-message-1 {
  position: absolute;
  font-size: 12px;
  color: red;
}

.input-error {
  border: 1px solid red !important;
  border-radius: 4px;
}
.input-bottom-error {
  border-bottom: 1px solid red !important;
}


.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  height: 70px !important;
  width: 55px !important;
}
.react-international-phone-input-container .react-international-phone-input {
  height: 60px !important;
}
.react-international-phone-input-container {
  width: 100% !important;
}

.form-input{
  background: white;
}
.form-input.form-input-phone label {
  left: 16% !important;
  top: 14% !important;
}

.form-select-override {
  position: relative;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: var(--bs-body-color);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-select-override .select__control {
  height: 71px !important;
  padding: 0 15px !important;
}
.react-select-z-index .react-select-z-index__control{
  z-index: 10001 !important;
}

.clear-icon {
  position: absolute;
  right: -15px !important; /* Adjust based on spinner size */
  top: 45%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #9c7d5c;
  cursor: pointer;
  font-weight: bold;
}

.spinner-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #666;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}


.dropdown-toggle {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  width: 100%;
  // padding: 10px 15px;
  // border: 1px solid #ccc;
  border-radius: 4px;
}

.searchMenu-loc .dropdown-toggle::after {
  display: none !important;
}

.searchMenu-loc .dropdown-menu.show {
  margin-top: 22px;
  
  // left: -35px !important;
}


.galleryGrid__items{
  width: auto!important;
  height: 300px!important;
}

.pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  padding-right: 10px;
  list-style: none;
}

.page-link {
  display: block;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 2px;
  text-decoration: none;
  border-radius: 34px;
  padding: 6px 18px;
}

a.page-link:hover {
  background-color: #cccccc;
}

.page-item.active .page-link {
  color: #ffffff;
  background-color: #032843;
}
.page-item:first-child .page-link {
  border-radius: 34px;
    padding: 6px 16px;
}
.page-item:last-child .page-link {
  border-radius: 34px;
    padding: 6px 16px;
}
.pagination_loading {
  display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.form-input label {
  top: 9px !important;
}


/* Apply a grid layout with two columns */
.billing-form .ElementsApp {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  gap: 16px; /* Add spacing between fields */
}

/* Ensure each input spans one column */
.billing-form .ElementsApp .InputContainer {
  grid-column: span 1;
}

/* Make certain fields span two columns */
.billing-form .ElementsApp .InputContainer[data-name="line1"],
.billing-form .ElementsApp .InputContainer[data-name="line2"],
.billing-form .ElementsApp .InputContainer[data-name="postal_code"] {
  grid-column: span 2; /* Make these fields full width */
}


.popular-destination-container {
  width: 100%;
  // max-width: 1200px; /* Adjust based on your layout */
  margin: 0 auto;
  overflow: hidden; /* Prevents horizontal scroll */
}


#deals.cardImage__leftBadge {
  z-index: 1;
}

.deal_image {
  min-height: 320px;
  object-fit: cover;
  object-position: center;
}

.onHover{
  cursor: pointer !important;
}
#home_hotels .swiper-slide {
  height: 350px !important;
}
.borderradius-4{
  border-radius: 4px !important;
}

.single-field #email {
  padding-top: 0px !important;
  min-height: 0px !important;
}

.react-select-z-index__menu {
  z-index: 100001 !important;
  min-height: 400px !important;
}



.select__value-container {
  // top: -19px !important;
  font-size: 12px !important;
  color: #29354d !important;
  line-height: 1 !important;
}

.progress-bar {
   background-color: #9c7d5c !important;
}
// .swiper {
//   width: 100%;
//   overflow: hidden;
// }

// .swiper-wrapper {
//   display: flex;
// }

// .swiper-slide {
//   max-width: 100%;
//   flex-shrink: 0;
// }

.referral-code-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  // border: 2px dashed #ffd966;
  // background-color: #fffbea;
  border-radius: 8px;
  flex-direction: column;
}

.referral-code {
  font-weight: bold;
  font-size: 1.5rem;
  color: #1a1a1a;
}
.copy-text {
  font-size: 14px;
  color: #6c757d;
  cursor: pointer;
  font-weight: bold;
}
.copy-text:hover {
  text-decoration: underline;
}

.profile_li li{
  list-style-type: disc !important;
  display: list-item !important; /* Ensure it's a list item */
}
