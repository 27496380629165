[data-anim-wrap] {
  pointer-events: none;
  &.animated { pointer-events: auto; }
}

@keyframes reveal {
  100% {
    opacity: 1;
  }
}


// Slide anim
$slide-distance: 40px;
$slide-duration: 1.0s;

[data-anim^='slide-'],
[data-anim-child^='slide-'] {
  opacity: 0;
  transition-property: opacity, transform;
  pointer-events: none;

  transition-duration: $slide-duration;
  transition-timing-function: $easeOutCubic;

  &.is-in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
  }
}

[data-anim^='slide-up'],
[data-anim-child^='slide-up'] {
  transform: translate3d(0, $slide-distance, 0);
}

[data-anim^='slide-down'],
[data-anim-child^='slide-down'] {
  transform: translate3d(0, -$slide-distance, 0);
}

[data-anim^='slide-right'],
[data-anim-child^='slide-right'] {
  transform: translate3d(-$slide-distance, 0, 0);
}

[data-anim^='slide-left'],
[data-anim-child^='slide-left'] {
  transform: translate3d($slide-distance, 0, 0);
}


// Fade animation

[data-anim^='fade'],
[data-anim-child^='fade'] {
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;

  transition-duration: 0.8s;
  transition-timing-function: $easeInOutCubic;

  &.is-in-view {
    opacity: 1;
    pointer-events: auto;
  }
}
